import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    clientList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    company: "",
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      if (this.clientList.length > 0) {
        return (
          _.where(this.clientList, { isSelected: true }).length ===
          this.clientList.length
        );
      }
      return false;
    },
  },
  methods: {
    checkUncheckedList(event) {
      this.clientList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific ID is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate through the client list to find selected IDs
        this.clientList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Send a POST request to delete the records
      _vm.axios
        .post("/client-deletes", { ids: ids })
        .then(function (response) {
          // Refresh the data after successful deletion
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {
          // Handle any errors during the deletion process
        });
    },

    search(value) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getAll();
      }, 1000);
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send GET request to retrieve client data
      this.axios
        .get("/client" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          // Set isSelected property to false for each client
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.clientList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {
          // Handle errors here if necessary
        });
    },

    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Update selectedRows with checkbox IDs for all city groups
          this.selectedRows = this.clientList.map(
            (item) => "checkbox" + item.id
          );
          // Set isSelected property to true for all city groups
          this.clientList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Reset selectedRows to an empty array
          this.selectedRows = [];
          // Set isSelected property to false for all city groups
          this.clientList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the checked checkbox ID to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the unchecked checkbox ID from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
    const style = document.createElement("style");
    style.innerHTML = `
      @media (max-width: 767px) {
        .iq-card .iq-card-header  {
          width: 1400px !important;
        }
      }
    `;
    document.head.appendChild(style);
  },
};
